<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <Label>Paciente</Label>
                <select
                    class="form-control"
                    id="formEventMeetPacient"
                    v-model="agenda.extendedProps.pacient.id"
                >
                <option value="">selecione um valor</option>
                <option
                    v-for="(item, index) in pacients"
                    :key="index"
                    :value="item.id"
                >{{ item.nome }}</option>
                </select>
            </div>
            <h2></h2>
        </div>
        <div class="row mt-2 pt-2">
            <div class="col-md-12">
                <label class="col-md-12 col-form-label">Profissionais:</label>
            </div>
            <div class="col-12">
                <div class="form-group row"
                    v-for="(prof, idx) in agenda.extendedProps.professionals"
                    :key="idx"
                >
                    <div class="col-md-1">
                        <input class="form-control text-right" v-model="prof.id" disabled />
                    </div>
                    <div class="col-md-7">
                        <select :id="'proficional-'+prof.id" class="form-control" disabled>
                            <option
                                v-for="(item, index) in professionals"
                                :key="index"
                                :value="item.id"
                                :selected="prof.id == item.id"
                            >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" v-model="prof.specialty" disabled/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        agenda: {type: Object, required: true},
        pacients: {type: Array, required: true},
        professionals: {type: Array}
    },
    data() {
        return {
            pacient: {},
            profissionais: [],
        }
    },
    created() {},
    mounted() {
        this.profissionais = this.agenda.extendedProps.professionals;
    },
    methods: {
        deleteProf: function(index) {
            this.agenda.extendedProps.professionals.splice(index, 1);
        }
    },
}
</script>